<template>
  <dl class="row">
    <dt class="col-sm-3 text-right text-muted">#</dt>
    <dd class="col-sm-9">
      <b><copy-value :value="order.id" /></b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Client</dt>
    <dd class="col-sm-9">
      <router-link :to="{ name: 'client', params: { id: order.user.id } }">
        {{ order.user.fullname }}
      </router-link>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Statut</dt>
    <dd class="col-sm-9">
      <b>{{ $t(`ORDER.${order.status}`) }}</b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Validée le</dt>
    <dd class="col-sm-9">
      <span v-if="order.validated_at">
        {{ $dayjs(order.validated_at).format('DD-MM-YYYY HH:mm') }}
      </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Paiement</dt>
    <dd class="col-sm-9">
      <span v-if="order.validated_at">
        {{ order.payment_service }} par {{ $t(order.payment_method) }}
        <br />
        # <b><copy-value :value="order.payment_id" /></b>
      </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Livraison le</dt>
    <dd class="col-sm-9">
      <span v-if="order.shipped_at">
        {{ $dayjs(order.shipped_at).format('DD-MM-YYYY') }}
      </span>
    </dd>
    <template v-if="order.user_coupon && order.user_coupon.coupon">
      <dt class="col-sm-3 text-right text-muted">Réduction</dt>
      <dd class="col-sm-9">
        <span>
          {{ order.user_coupon.coupon.label_formated }}
        </span>
      </dd>
    </template>
    <dt class="col-sm-3 text-right text-muted">Total HT</dt>
    <dd class="col-sm-9">
      {{ order.total_excluding_tax | currency }}
    </dd>
    <template v-if="order.coupon && order.coupon.id">
      <dt class="col-sm-3 text-right text-muted">
        Grand total avant réduction
      </dt>
      <dd class="col-sm-9">
        {{ order.grand_total_without_coupon | currency }}
        (montant de la réduction
        {{ (order.grand_total_without_coupon - order.grand_total) | currency }})
      </dd>
    </template>
    <dt class="col-sm-3 text-right text-muted">Grand total</dt>
    <dd class="col-sm-9">
      <b>{{ order.grand_total | currency }}</b>
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    order: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
