<template>
  <div>
    <b-card v-if="order.id">
      <div class="row">
        <div class="col-lg-8">
          <h3>
            Commande du {{ $dayjs(order.created_at).format('DD-MM-YYYY') }}
            <br />
            <small>
              Mise à jour le
              {{ $dayjs(order.updated_at).format('DD-MM-YYYY à HH:mm') }}
            </small>
          </h3>
        </div>
        <div class="col-lg-4 text-right">
          <a :href="order.invoice_url" target="_blank" class="btn btn-info">
            <font-awesome-icon icon="file-pdf" />
            Facture
          </a>
        </div>
      </div>
      <b-tabs content-class="mt-2">
        <b-tab title="Propriétés" active>
          <order-card :order="order" />
        </b-tab>
        <b-tab title="Métadonnées">
          <pre>{{ order }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card>
      <h3>Articles</h3>
      <div class="row">
        <div class="col-lg-12">
          <table class="table">
            <template v-for="item in order.active_items">
              <tbody :key="item.id">
                <tr>
                  <td>
                    <copy-value :value="item.id" :display="item.public_id" />
                  </td>
                  <td>{{ $t(`ORDER.${item.status}`) }}</td>
                  <td>{{ $t(`ORDER.${item.product_type}`) }}</td>
                  <td>{{ item.quantity }}</td>
                  <td class="text-right">{{ item.total_price | currency }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="4">
                    <p>
                      <b>{{ item.label }}</b>
                    </p>
                    <div
                      v-if="
                        item.product_type == 'SUBSCRIPTION_PLAN' &&
                        item.attributes.recipes
                      "
                    >
                      <ul>
                        <li
                          v-for="recipe in item.attributes.recipes"
                          :key="recipe.id"
                        >
                          {{ recipe.quantity }}
                          x {{ recipe.label }}
                        </li>
                      </ul>
                      <p v-if="item.attributes.user_subscription_id">
                        <router-link
                          :to="`/subscriptions/${item.attributes.user_subscription_id}`"
                        >
                          Voir l’abonnement associé
                        </router-link>
                      </p>
                    </div>
                    <div
                      v-if="
                        item.product_type == 'VOUCHER' &&
                        item.attributes.coupon_id
                      "
                    >
                      <p v-if="item.attributes.coupon_id">
                        <router-link
                          :to="`/coupons/${item.attributes.coupon_id}`"
                        >
                          Voir le coupon associé
                        </router-link>
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
    </b-card>
    <!-- <pre>{{ order }}</pre> -->
  </div>
</template>

<script>
import OrderCard from '@/components/OrderCard.vue'
import { BCard, BTabs, BTab } from 'bootstrap-vue'

const orderFields =
  'id,status,created_at,updated_at,invoice_url,' +
  'payment_data,shipped_at,validated_at,payment_service,payment_method,payment_id,' +
  'grand_total_without_coupon,grand_total,total_excluding_tax,' +
  'active_items{id,public_id,status,product_type,quantity,label,total_price,attributes},' +
  'user{id,fullname},user_coupon{coupon{id,label_formated}}'

export default {
  components: {
    OrderCard,
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      order: {},
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(`/orders/${this.$route.params.id}/`, {
        headers: {
          'X-Fields': orderFields,
        },
      })
      this.order = resp.data
    },
  },
}
</script>
